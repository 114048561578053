(function (global) {

    var Website = function (options) {
        this.isMobile = undefined;
        this.opts = options;
        this.$global = $(global);

        this.pages = {};

        // check / listen width
        this.onResize();
        this.$global.resize(this.onResize.bind(this));
    };


    Website.prototype = {
        onResize: function () {


            /*var sidebarHeight = $(".content .right").outerHeight();
             var contentHeight = $(".content .left").outerHeight();

             if(sidebarHeight <= contentHeight) {
             $(".content .left").css("height", "auto");
             }
             if(sidebarHeight > contentHeight) {
             $(".content .left").css({
             height: sidebarHeight
             } ,100);
             }

             */

            /*
             var contactDataHeight = $('#contact--data .left').outerHeight();
             var contactDataWidth = $('#contact--data').outerWidth();

             var isMobile = this.$global.width() < this.opts.mobileBreakpoint;


             if (isMobile) {
             var contactDataLeftWidth = $('#contact--data .left').outerWidth();
             var marginLeft = (contactDataWidth - contactDataLeftWidth) / 2 + 15;


             $('#googlemaps').animate({
             position: 'relative',
             height: contactDataHeight,
             width: contactDataWidth,
             left: -marginLeft,
             }, 1).css("position", "relative");
             }

             else {


             $('#googlemaps').animate({
             height: contactDataHeight,
             width: contactDataWidth / 3 +32,
             left: 15,
             }, 100);


             $('body').toggleClass('mobile', isMobile);
             this.isMobile = isMobile;
             //$.publish('website.isMobile', [this.isMobile]);
             }
             */
        }
    };

    global.website = new Website({
        mobileBreakpoint: 768
    });

}(window));

$(document).ready(function () {
    website.onResize();

    $('#language-selector-link').click(function(){
        $('#language-dropdown').toggle();
    });

    $('a[data-rel^=lightcase]').lightcase({
        swipe: true,
        maxWidth: 1400,
        maxHeight: 1000,
    });

    $('#newsletter-form').submit(function (event) {
        event.preventDefault();
        submitNewsletterForm();
    });

    function submitNewsletterForm() {
        $.ajax({
            type: "POST",
            url: "/newsletter/subscribe",
            dataType: "json",
            data: $('#newsletter-form').serialize(),
            success: function (data) {
                if (data.success) {
                    NewsletterFormSuccess();
                }
            },
            error: function () {
                NewsletterFormError();
            }
        });
    }

    function NewsletterFormSuccess() {
        $('.newsletter-message.alert-danger').hide();
        $('#newsletter-form-description').hide();
        $('.newsletter-message.alert-success').fadeIn();
        $('#newsletter-form').hide();
    }

    function NewsletterFormError() {
        $('.newsletter-message.alert-success').hide();
        $('#newsletter-form-description').hide();
        $('.newsletter-message.alert-danger').fadeIn();
    }


});


