'use strict';

window.jQuery = window.$ = require('jquery');



/* require('bootstrap') will load all of Bootstrap’s jQuery plugins onto the jQuery object.
The bootstrap module itself does not export anything. You can manually load Bootstrap’s jQuery
plugins individually by loading the /js/*.js files under the package’s top-level directory.
 */

//global.Tether = require("tether");

//require('bootstrap');
require('popper.js');
require('lightcase');
require("./utils/main.js");

require("rrssb");
require("./plugins/formvalidation.io/formValidation.js");
require("./plugins/formvalidation.io/language/de_DE.js");
require("./plugins/formvalidation.io/framework/bootstrap4.js");

//require('bxslider');


$(document).ready(function () {
    $('.sldm-toggle, .sldm-overlay').on("click", function (e) {
        e.preventDefault();
        $('.sldm').toggleClass('sldm-active');
        $('.sldm-bg-image').toggleClass('active');
    });

    $('.sldm-submenu > a').on("click", function (e) {
        e.preventDefault();
        $(this).toggleClass('sldm-open');
        $(this).parent().find('>ul').slideToggle(450);
    });

    $('.sldm-widget-toggle').on("click", function (e) {
        e.preventDefault();
        $($(this).data('target')).slideToggle(300);
    });
});
